@import "../variables";
@import "../mixins";

#EmailModal {
  .modal-dialog {
    margin: 0 auto;

    @include bp(md) {
      margin: 0.5rem auto;
    }

    .modal-content {
      .btn-close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        z-index: 1;
      }

      .modal-body {
        padding: 2rem 1rem 1.25rem;

        &.check-email {
          padding: 2rem .5rem 1.25rem;
        }

        h5 {
          font-size: 2rem;
          font-weight: 600;
          text-align: center;
          color: $darkblue;
        }

        p {
          font-size: 1.25rem;
          font-weight: 600;
          text-align: center;
          color: $lightblue;
        }

        form {
          display: flex;
          flex-direction: column;
          align-items: center;

          input[type="email"] {
            color: #5f6062;
            font-size: 1.25rem;
            margin: 0.5rem auto 1rem;

            &:focus {
              border-color: $lightblue;
              outline: 0;
              box-shadow: 0 0 0 0.25rem rgba(0, 159, 199, 0.25);
            }
          }

          .btn {
            width: 200px;
            background-color: $lightblue;
            color: #ffffff;
            border: 1px solid $lightblue;
            border-radius: 30px;
            font-size: 1.25rem;
            letter-spacing: 1px;
            padding: 10px;

            &:focus {
              border-color: $lightblue;
              outline: 0;
              box-shadow: 0 0 0 0.25rem rgba(0, 159, 199, 0.25);
            }
          }
        }
      }
    }
  }
}

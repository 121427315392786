@import "../variables";
@import "../mixins";

#Builder {
  .container {
    max-width: 800px;
    padding: 0;
  
    @include bp(md) {
      padding: unset;
    }

    .get-the-details {
      width: 90%;
      margin: 1.25rem auto 0.75rem;
    
      @include bp(md) {
        max-width: 425px;
        margin: 1.5rem auto 0.75rem;
      }
    }

    h1 {
      font-size: 2.5rem;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;
      line-height: 1;
      letter-spacing: 1.5px;
      color: $mediumblue;
      margin: 1.25rem auto 0.75rem;
    
      @include bp(md) {
        margin: 1.75rem auto 0.75rem;
      }
    
      br {
        @include bp(md) {
          display: none;
        }
      }
    }

    .subtitle {
      font-size: 1.4rem;
      text-align: center;
      line-height: 1.8rem;
      color: $darkblue;
      margin-bottom: 0.5rem;
    }

    .specifications {
      text-align: center;
    
      p {
        margin-bottom: 0;
      }
    
      .line-item {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        color: $darkblue;
    
        &.sq {
          justify-content: center;
        }
    
        &.option {
          justify-content: center;
        }
    
        svg {
          margin-right: 0.25rem;
        }
      }
    
      .specs {
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
    
        .line-item {
          margin-right: 1.5rem;
          line-height: 1;
    
          &:nth-of-type(3) {
            margin-right: 0;
          }
        }
      }
    
      .sign-icon {
        fill: none;
        stroke: $darkblue;
        width: 33px;
        height: 33px;
      }
    
      .bed-icon {
        fill: $darkblue;
        width: 33px;
        height: 33px;
      }
    
      .bath-icon {
        fill: $darkblue;
        width: 33px;
        height: 33px;
      }
    
      .garage-icon {
        fill: none;
        stroke: $darkblue;
        width: 25px;
        height: 25px;
        margin-right: 0.65rem !important;
      }
    }

    .instructions {
      text-align: center;
      margin-top: 1.25rem;
    
      @include bp(md) {
        font-size: 1.25rem;
        margin-top: 1.5rem;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
    
      @include bp(md) {
        max-width: 400px;
        margin: 0 auto;
      }
    
      .btn {
        background-color: $darkblue;
        color: #ffffff;
        border: 1px solid $darkblue;
        border-radius: 30px;
        font-size: 1.25rem;
        letter-spacing: 1px;
        padding: 10px;
    
        &:first-of-type {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
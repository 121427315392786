@import "../variables";
@import "../mixins";

#home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;

  img {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 210px;
  }

  a {
    font-size: 2rem;
    color: $darkblue;
    text-decoration: none;

    br {
      display: none;
    }
  }
}
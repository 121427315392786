@mixin bp($breakpoint) {
  $num: $breakpoint;
  @if $breakpoint == sm {
    $num: 576;
  }
  @if $breakpoint == md {
    $num: 768;
  }
  @if $breakpoint == lg {
    $num: 992;
  }
  @if $breakpoint == xl {
    $num: 1200;
  }
  @if $breakpoint == xxl {
    $num: 1400;
  }

  @media only screen and (min-width: $num + px) {
    @content;
  }
}
@import "../variables";
@import "../mixins";

#CheckboxList {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0;

  @include bp(md) {
    max-width: 500px;
    margin: 0 auto;
  }

  .list-group-item {
    display: flex;
    align-items: center;
    font-size: 1.75rem;
    text-transform: capitalize;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 2rem;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  .form-check-input {
    width: 3rem;
    height: 3rem;
    padding-right: 1rem;
    margin-top: 0;

    &:checked {
      background-color: $lightblue;
      border-color: $lightblue;

      + span {
        color: $lightblue;
      }
    }

    &:focus {
      border-color: $lightblue;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(0, 159, 199, 0.25);
    }
  }

  span {
    font-weight: 600;
    line-height: 2rem;
    color: $gray;
    margin-left: 1rem;
  }
}